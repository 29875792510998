import {
    Env
} from './env.js'; //ブラウザなど環境チェック
import {
    Swp
} from './swp.js'; //Swiper
import { VimeoManager } from './vimeo.js';
import {
    PageAncher
} from './page-ancher.js'; //ページ内アンカーリンク（スムーズスクロール）
import {
    BtnGdpr
} from './btn-gdpr.js';
import smoothscroll from 'smoothscroll-polyfill';
// https://www.npmjs.com/package/smoothscroll-polyfill
// http://iamdustan.com/smoothscroll/

// window.scroll smooth not working on Safari
// https://stackoverflow.com/questions/51731754/window-scroll-smooth-not-working-on-safari
// Window.scroll() https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll

export class Main {
    constructor() {
        this.timerResized = false; //resize時
        this.timerScrolled = false; //scroll時
        this._click = '';
        this.env = {};
        this.loadedFlg = false;
        this.scrChecker = {};
        this.sliders = {};
        this.openingManager = {};
        this.tabs = {};
        this.resize = {};
        this.body = {};
        this.html = {};
    }

    init() {
        //console.log('init');
        //this.scrCheck = new ScrPos();
        this.body = document.getElementsByTagName('body')[0];
        this.html = document.getElementsByTagName('html')[0];

        // DOMContentLoaded時
        // ------------------------------
        window.addEventListener('DOMContentLoaded', () => {

            // ブラウザOS環境
            this.env = new Env();
            this.env.detectEnv();

            // スクロールチェッカー
            // this.scrChecker = new ScrChecker();
            // this.scrChecker.init();

            //page ancher
            smoothscroll.polyfill();
            this.pageAncher = new PageAncher();
            this.pageAncher.init();


            //SizeInt
            //this.sizeint = new SizeInt();

            // Resize
            //this.resize = new ResizeElem();

            // スクロール時
            window.addEventListener('scroll', () => {
                //this.scrChecker.onUpdate();
                this.vimeoManager.onScroll();
            });

            // ウインドウリサイズ時
            window.addEventListener('resize', () => {
                // this.scrChecker.onUpdate();
                //var _this = this;
                if (this.timerResized !== false) {
                    clearTimeout(this.timerResized);
                }
                this.timerResized = setTimeout(() => {
                    //this.resize.resizing();
                    this.vimeoManager.onScroll();
                }, 300);
            });

            //btn
            this.btn = new BtnGdpr();
            this.btn.init();

            //vimeo
            this.vimeoManager = new VimeoManager();
            this.vimeoManager.init();

        });

        // load時
        // ------------------------------
        window.addEventListener("load", () => {
            this.loadedFlg = true;
            window.setTimeout(() => {
                document.getElementsByTagName('body')[0].classList.add('loaded');

            }, 400);

            // Swiper
            this.sliders = new Swp();
            this.sliders.init();

            //this.vimeoManager.autoPlayInit();

        });
    }
}