import Cookies from 'js-cookie';

// Googleタグマネージャが同意の設定に対応
// https://ayudante.jp/column/2021-06-02/13-00/

// 個人情報保護法改正に伴うCookie同意の対応について
// https://tech.macloud.jp/entry/2022/05/27/151006

// ユーザーの同意ステータスに基づいてタグの動作を調整する
// https://developers.google.com/tag-manager/consent?hl=ja

export class BtnGdpr {
    constructor() {
        this.body = {}
        this.html = {}
        this.btn = {}
        this.agreeFlg = false;//過去の同意済み
    }




    init() {
        // ユーザーの同意ステータスに基づいてタグの動作を調整する
        // https://developers.google.com/tag-manager/consent?hl=ja
        window.dataLayer = window.dataLayer || [];
        function gtag(){
            dataLayer.push(arguments);
        }
        function consentGranted() {
            gtag('consent', 'update', {
              'ad_storage': 'granted'
            });
        }

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        });
        dataLayer.push({
            'event': 'default_consent'
        });

        // 過去の同意をチェック
        if (typeof Cookies.get('agree-flg') === 'undefined') {
            this.agreeFlg = false;
            document.querySelectorAll('.cookie')[0].classList.remove('hide');
        }else{
            this.agreeFlg = true;
            document.querySelectorAll('.cookie')[0].classList.add('hide');
            consentGranted();
        }

        // ボタン
        if (document.querySelectorAll('.button-agree')[0]) {
            this.btn  = document.querySelectorAll('.button-agree')[0];

            this.btn.addEventListener('click', () => {
                if(!this.agreeFlg){
                    Cookies.set('agree-flg', 'ja', { expires: 30, path: '/' });
                    document.querySelectorAll('.cookie')[0].classList.add('hiding');
                    consentGranted();
                }
            });
        }
    }

}