// スムーススクロール
// import SmoothScroll from 'smooth-scroll';
// https://github.com/cferdinandi/smooth-scroll

// スムーススクロール "window.scroll" polyfill
import smoothscroll from 'smoothscroll-polyfill';
// https://www.npmjs.com/package/smoothscroll-polyfill
// http://iamdustan.com/smoothscroll/

// "window.scroll" smooth not working on Safari
// https://stackoverflow.com/questions/51731754/window-scroll-smooth-not-working-on-safari
// Window.scroll() https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll

export class PageAncher {

    constructor() {
        this.triggers = {};
        this.body = {};
    }

    init() {
        this.body = document.getElementsByTagName('body')[0];

        // vanilla JS smoooth scroll
        this.links = document.querySelectorAll('.js-trigger');

        //window.scroll smooth not working on Safari
        smoothscroll.polyfill();

        for (let i = 0; i < this.links.length; i++) {
            //console.log(i);
            this.links[i].addEventListener('click', (e) => {
                //console.log('clicked');
                e.preventDefault();

                // 対象のY軸の絶対座標を取得
                //const targetElem = this.links[i].getBoundingClientRect().top;
                let targetElemId = this.links[i].getAttribute('href').replace(/#/g, '');
                let isTargetElem = false;

                let targetElemY;
                let offsetY;
                let scrollBuffer;
                let targetTopY;

                if (document.getElementById(targetElemId)) {
                    targetElemY = document.getElementById(targetElemId).getBoundingClientRect().top;
                    isTargetElem = true;

                    // 現在のスクロール量を取得
                    offsetY = window.pageYOffset;

                    // スクロール位置に持たせるバッファ
                    scrollBuffer = 70;

                    //ie11

                    if (this.body.classList.contains('ie11')) {
                        scrollBuffer = 0;
                        if (targetElemId == 'contact') {
                            scrollBuffer = 70;
                        }
                    }

                    // 対象までのスクロール量を算出
                    targetTopY = targetElemY + offsetY - scrollBuffer;

                    //console.log('targetElemY=' + targetElemY + ', offsetY='+offsetY);


                    window.scroll({
                        top: targetTopY, // スクロール量の設定
                        behavior: 'smooth' // スクロール動作の設定
                    });
                }


                //analytics event
                // let eventLabel = targetElemId;
                // gtag( 'event','click', {
                //     'event_category': 'gloabl_menu',
                //     'event_label'   : eventLabel,
                //     'event_callback': function() {
                //         console.log('gtag event: ' + eventLabel);
                //     }
                // });


            });
        }
    }


}