import Player from '@vimeo/player';
//https://www.npmjs.com/package/@vimeo/player
import MicroModal from 'micromodal';

export class VimeoManager {
    constructor() {
        this.vimeoPlayers = {};
        this.vimeoElems = {};
        this.btnElems = {};
        this.body = {};
        this.html = {};
    }

    onScroll(){
        //console.log('vimeo: onscroll');
    }

    // 初期化
    init() {
        //console.log('vimeo: init');
        //this.windowWidthThen = window.innerWidth;
        this.body = document.getElementsByTagName('body')[0];
        this.html = document.getElementsByTagName('html')[0];

        // vimeo: Players
        if(document.querySelectorAll('.js-video')[0]){
            this.vimeoElems = document.querySelectorAll('.js-video');
            [].forEach.call(this.vimeoElems, (elem) => {
                const vimeoId = elem.dataset.vimeo;
                //console.log(elem.dataset.vimeo);
                //console.log(elem);
                let options = {};
                
                if(elem.classList.contains('js-show-in-modal')){
                    // brand: bgとして自動再生する
                    options = {
                        id: vimeoId,
                        width: 640,
                        loop: true,
                        autoplay: true,
                        muted: true,
                        controls: false,//controls: true,
                        background: 1,
                        quality: '1080p'
                    };
                }else{
                    //通常のvimeo
                    options = {
                        id: vimeoId,
                        width: 640,
                        loop: false,
                        autoplay: false,
                        muted: true,//false,
                        //controls: false,
                        //background: bgValue,
                        quality: '1080p'
                    };
                }
                this.vimeoPlayers[vimeoId] = new Player(elem, options);

            });

            // vimeo: startBtns
            if(document.querySelectorAll('.js-video-poster')[0]){
                this.btnElems = document.querySelectorAll('.js-video-poster');

                // [].forEach.call(this.vimeoElems, (elem) => {
                //     if(emlem.classList.contains('js-show-in-modal')){
                //         elem.mode = 'modal';
                //     }else{
                //         elem.mode = 'direct';
                //     }
                // });

                [].forEach.call(this.btnElems, (elem) => {
                    const targetId = elem.dataset.target;//vimeoId

                    if(elem.classList.contains('modal-open')){
                        // brand: モーダルで再生する・MicroModalで挙動を指定
                    }else{
                        //通常のvimeo
                        elem.addEventListener('click', () => {
                            elem.classList.add('hide');
                            this.vimeoPlayers[targetId].controls = 0;
                            this.vimeoPlayers[targetId].play();
                        });
                    }

                });
            }


            MicroModal.init({
                onShow: (modal) => this.showModal(modal.id),
                onClose: (modal) => this.closeModal(modal.id),
                disableScroll: true,
                //awaitOpenAnimation: true,
                //awaitCloseAnimation: true,
                debugMode: true,
            });

        }
    }

    showModal(modalId){
        const modalElem = document.getElementById(modalId);
        const targetId = modalElem.querySelector('.js-video').dataset.vimeo;
        this.vimeoPlayers["782401626"].pause();//bg
        this.vimeoPlayers[targetId].play();
    }
    closeModal(modalId){
        const modalElem = document.getElementById(modalId);
        const targetId = modalElem.querySelector('.js-video').dataset.vimeo;
        this.vimeoPlayers["782401626"].play();//bg
        this.vimeoPlayers[targetId].pause();
        console.log(targetId);
    }

    // autoPlayInit(){
    //     [].forEach.call(this.vimeoElems, (elem) => {
    //         const vimeoId = elem.dataset.vimeo;
    //         if(vimeoId == "782401626"){
    //             this.vimeoPlayers[vimeoId].play();
    //         }
    //     });
    // }
}