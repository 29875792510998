export class Env {
    constructor() {
        this.ua = '';
        this.isiPhoneiPad = false;
        this.isiPhoneXSeries = false;
        this.isPC = false;
        this.body = {};
        this.html = {};
    }
    detectEnv() {
       this.body = document.getElementsByTagName('body')[0];
       this.html = document.getElementsByTagName('html')[0];
       //this.browser = {};


        // userAgentData が有効ならば、userAgentDataで判定をする
        if ('userAgentData' in window.navigator) {
            var userAgentData = window.navigator.userAgentData;
            if (userAgentData) {
                // ブラウザがuserAgentData に対応している場合
                var brands = userAgentData.brands;
                for (var i = 0; i < brands.length; i++) {
                    var brand = brands[i].brand;
                    if (brand.indexOf("Google Chrome") !== -1) {
                        // Chrome
                        this.body.classList.add("chrome");
                    } else if (brand.indexOf("Microsoft Edge") !== -1) {
                        // Edge(EdgeHTML or Chromium)
                        this.body.classList.add("edge");
                    } else if (brand.indexOf("opera") !== -1) {
                        // Opera
                        this.body.classList.add("opera");
                    }
                }
                //console.log("aaaa");
            }
        } else {
            // ブラウザがuserAgentData に対応していない場合
            //console.log("bbbb");

            // ブラウザ判定
            this.ua = window.navigator.userAgent.toLowerCase();
            if (this.ua.indexOf("msie") !== -1 || this.ua.indexOf("trident") !== -1) {
                // IE
                this.body.classList.add("ie11");
            } else if (this.ua.indexOf("edge") !== -1) {
                // Edge(EdgeHTML)
                this.body.classList.add("edge");
            } else if (this.ua.indexOf("edg") !== -1) {
                // Edge(Chromium)
                this.body.classList.add("edge");
            } else if (this.ua.indexOf("chrome") !== -1) {
                // Chrome
                this.body.classList.add("chrome");
            } else if (this.ua.indexOf("safari") !== -1) {
                // Safari
                this.body.classList.add("safari");
            } else if (this.ua.indexOf("firefox") !== -1) {
                // FireFox
                this.body.classList.add("firebox");
            } else if (this.ua.indexOf("opera") !== -1) {
                // Opera
                this.body.classList.add("opera");
            }

            // OS判定
            if (this.ua.indexOf("iphone") !== -1) {
                this.body.classList.add('os-ios');
                this.body.classList.add('is-iphone');
                this.isiPhoneiPad = true;
            } else if (this.ua.indexOf("ipad") !== -1) {
                this.body.classList.add('os-ios');
                this.body.classList.add('is-ipad');
                this.isiPhoneiPad = true;
            } else if (this.ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
                // iPad: iOS 13以降のiPadのSafariには「デスクトップ用Webサイトを表示」という機能が実装されており、それがオンになっていると（デフォルト）ユーザーエージェントに"iPad"の文字が含まれなくなるようです。
                this.body.classList.add('os-ios');
                this.body.classList.add('os-ios-desktop-mode');
                this.isiPhoneiPad = true;
            } else if (this.ua.indexOf("windows nt") !== -1) {
                this.body.classList.add('os-windows');
                this.isPC = true;
            } else if (this.ua.indexOf("android") !== -1) {
                this.body.classList.add('os-android');
            } else if (this.ua.indexOf("mac os x") !== -1) {
                this.body.classList.add('os-mac');
                this.isPC = true;
            }
        };







        if (this.isPC) {
            this.body.classList.add('is-pc-true');
        }

        //iPhoneX(ホームバー・ノッチのある機種)かどうか
        //const isiPhone = /iPhone/.test(window.navigator.userAgent);
        const currentDevicePixel = (window.screen.width * window.screen.height * window.devicePixelRatio);
        const iPhoneXSeriesScreenPixelList = [
            (375 * 812 * 3), // iPhone X, XS, 11 Pro
            (414 * 896 * 3), // iPhone XS Max, 11 Pro Max
            (414 * 896 * 2) // iPhone XR, 11
        ];
        if (this.isiPhoneiPad && iPhoneXSeriesScreenPixelList.some((item) => item === currentDevicePixel)) {
            this.isiPhoneXSeries = true;
            this.body.classList.add('os-ios-x');
        }

        // クリックとタップ判定　
        // 注 _click 指定時はクォーテーションで囲わないこと
        if (window.ontouchstart === null) { //null undefined
            this._click = 'touchstart';
            this.body.classList.add('device-touch');
            this.body.classList.remove('device-click');
            //console.log('resize-tou');
        } else {
            this._click = 'click';
            this.body.classList.add('device-click');
            this.body.classList.remove('device-touch');
            //console.log('resize-cl');
        }
    }

    getClick() {
        if (window.ontouchstart === null) {
            return 'touchstart';
        } else {
            return 'click';
        }
    }



}