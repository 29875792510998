//Swiper 4 (5.0からはIE11がサポートされていない)
//import Swiper from 'swiper';
//import Swiper from 'swiper/bundle';

// Swiper 6.0-
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';


export class Swp {
    constructor() {
        this.swipers = [];
    }

    init() {
        //this.body = document.getElementsByTagName('body')[0];
        //this.html = document.getElementsByTagName('html')[0];

        //index
        if (document.querySelectorAll('.index-slide')[0]) {
            this.swipers['index-slide'] = new Swiper('.index-slide', {
                modules: [Navigation, Pagination, Autoplay],
                loop: true,
                spaceBetween: 0,
                slidesPerView: "auto",
                centeredSlides: true,
                autoplay: {},
                speed: 900,
                breakpoints: {
                    980: {
                        spaceBetween: 14
                    }
                },
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                },
                on: {
                    init: function () {
                        //console.log('index-slide autoplay start');
                        //console.log(this.params);
                        this.params.autoplay.delay = 3000;
                        this.params.autoplay.disableOnInteraction = false;
                        this.autoplay.start();
                    }
                }
            });
        }
        
        //brand
        if (document.querySelectorAll('.brand-slide')[0]) {
            this.swipers['brand-slide'] = new Swiper('.brand-slide', {
                modules: [Navigation, Pagination],
                loop: true,
                spaceBetween: 30,
                slidesPerView: "auto",
                centeredSlides: true,
                speed: 900,
                pagination: {
                    el: '.swiper-pagination',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }

    }

}